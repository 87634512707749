<template>
  <v-btn
    x-large
    rounded
    class="welcome-btn"
    width="200"
    @click="click"
    id="enter"
  >
    {{ $t("action.enter") }}
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
let closeSfx_1 = new Audio(require("@/assets/sounds/sfx_enter_1.wav"));
let closeSfx_2 = new Audio(require("@/assets/sounds/sfx_enter_2.mp3"));
let closeSfx_3 = new Audio(require("@/assets/sounds/sfx_enter_3.mp3"));
let closeSfx_4 = new Audio(require("@/assets/sounds/sfx_enter_4.wav"));
export default {
  components: {
    //
  },
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  props: ["callback", "sfx"],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    click() {
      this.callback();
      switch (this.sfx) {
        case 1:
          closeSfx_1.play();
          closeSfx_1.volume = this.settings.audio.sfx * this.settings.audio.master;
          break;
        case 2:
          closeSfx_2.play();
          closeSfx_2.volume = this.settings.audio.sfx * this.settings.audio.master;
          break;
        case 3:
          closeSfx_3.play();
          closeSfx_3.volume = this.settings.audio.sfx * this.settings.audio.master;
          break;
        case 4:
          closeSfx_4.play();
          closeSfx_4.volume = this.settings.audio.sfx * this.settings.audio.master;
          break;
      }
    },
  },
};
</script>